import React from 'react'
import './portfolio.css'

const data = [

  {
    id: 21,
    image: "https://user-images.githubusercontent.com/71200950/183617073-9b415d7b-14ed-47b7-82e7-508db94852a9.gif",
    title: "Product Card",
    github: "https://github.com/tringuyen1086/product-card.git",
    demo: "https://tringuyen1086.github.io/product-card/",
    description: "A professional product card with animation built with html and css to display an item with different options.",
    tags: [
      "HTML",
      "CSS",
    ],
    date: "11 Jul, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/183617073-9b415d7b-14ed-47b7-82e7-508db94852a9.gif",
  },

  {
    id: 20,
    image: "https://user-images.githubusercontent.com/71200950/183607263-73b2dd7c-141f-46f6-b9e1-e648273474b6.png",
    title: "Tin Dog",
    github: "https://github.com/tringuyen1086/tinDog.git",
    demo: "https://tringuyen1086.github.io/tinDog/",
    description: "A demo web for Dog Tinder built with css and bootstrap.",
    tags: [
      "HTML",
      "CSS",
      "Bootstrap",
    ],
    date: "11 Jul, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/183606196-ef69b916-4231-4f3c-b404-e7a99d584540.mp4",
  },

  {
    id: 19,
    image: "https://user-images.githubusercontent.com/71200950/183637898-536232cc-6450-4ea2-9adc-3c66c73014db.gif",
    title: "My Website",
    github: "https://github.com/tringuyen1086/myWeb.git",
    demo: "https://tringuyen1086.github.io/myWeb/",
    description: "A website built with HTML and CSS.",
    tags: [
      "HTML",
      "CSS",
    ],
    date: "17 Jun, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/183637898-536232cc-6450-4ea2-9adc-3c66c73014db.gif",
  },
/* 
  // Remove cause of inactive Heroku from the group
  {
    id: 18,
    image: "https://user-images.githubusercontent.com/71200950/167048110-9e85c0f8-bf31-43d8-85c0-90e2ee6b218d.png",
    title: "Interactive MERN SPA Project - Maintainable",
    github: "https://github.com/horsfalm/maintainable.git",
    demo: "https://maintainable.herokuapp.com/",
    description: "An application for use in recording the details and maintenance history of the air conditioning assets of an HVAC service provider. It has been developed using a MERN SPA framework and accordingly, utilizes a MongoDB database, Express, React and Node with GraphQL.",
    tags: [
      "MERN",
      "GraphQL",
      "JWT Authentication",
      "Progressive Web Application (PWA)",
      "Full-Stack",
    ],
    date: "14 Apr, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/167048110-9e85c0f8-bf31-43d8-85c0-90e2ee6b218d.png",
  },
*/
  {
    id: 17,
    image: "https://user-images.githubusercontent.com/71200950/182466576-45f0e84f-0af7-4410-b9ea-9a3752b95830.png",
    title: "React Portfolio",
    github: "https://github.com/tringuyen1086/react-portfolio-pro.git",
    demo: "https://tringuyen1086.github.io/react-portfolio-pro/",
    description: "This React Portfolio Website is built to share online portfolio using React.",
    tags: [
      "React",
      "SASS",
    ],
    date: "27 Mar, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/182466576-45f0e84f-0af7-4410-b9ea-9a3752b95830.png",
  },
/*
  // Remove cause of inactive Heroku from the group
  {
    id: 16,
    image: "https://user-images.githubusercontent.com/71200950/159232883-0f21e1fd-9bed-4e8d-a61d-b66179983d70.png",
    title: "Budget Tracker",
    github: "https://github.com/tringuyen1086/social-network-api-ultimate.git",
    demo: "https://budget-tracker-ultimate.herokuapp.com/",
    description: "The Budget Tracker Ultimate provides users with offline access and functionality. Users can add and subtract funds to their budget's current balance with or without an internet connection. If transactions are entered during offline session, changes should be updated as soon as there is an internet connection.",
    tags: [
      "MongoDB",
      "Mongoose",
      "Express",
      "Node.js",
      "Progressive Web Application (PWA)",
    ],
    date: "20 Mar, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/159232883-0f21e1fd-9bed-4e8d-a61d-b66179983d70.png",
  },
*/
  {
    id: 15,
    image: "https://user-images.githubusercontent.com/71200950/160824539-be004675-9541-4f55-b58a-574fedc7660d.png",
    title: "Social Network API",
    github: "https://github.com/tringuyen1086/social-network-api-ultimate.git",
    demo: "https://github.com/tringuyen1086/social-network-api-ultimate.git",
    description: "This is a social network API using a NoSQL database. In this set of API, the MongoDB database is used to handle large amounts of unstructured data. Express.js is used for routing. Mongoose is used as an Object Data Modeling (ODM) library for MongoDB and Node.js. The moment package is installed to format time stamps.",
    tags: ["MongoDB", "Mongoose", "Express", "NoSQL", "API", "Node.js"],
    date: "13 Mar, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/160824539-be004675-9541-4f55-b58a-574fedc7660d.png",
  },
/*
  // not important >>> remove
  {
    id: 14,
    image: "https://user-images.githubusercontent.com/71200950/160578524-29384d45-38c7-433d-afd1-74634510153c.png",
    title: "Regex Tutorial",
    github: "https://gist.github.com/tringuyen1086/30d169fbbe6b7c99e9ee6f559d94aa81",
    demo: "https://gist.github.com/tringuyen1086/30d169fbbe6b7c99e9ee6f559d94aa81",
    description: "Regular Express (Regex) Tutorial - Friendly Guide for Flags.",
    tags: ["Regex", "Gist"],
    date: "6 Mar, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/160578524-29384d45-38c7-433d-afd1-74634510153c.png",
  },
*/
/*
  // Remove cause of inactive Heroku from the group
  {
    id: 13,
    image: "https://user-images.githubusercontent.com/71200950/160572835-eec17800-f5bd-4da4-8790-6f1717f2348a.png",
    title: "Interactive Full-Stack Project - Messaging App",
    github: "https://tella.herokuapp.com/",
    demo: "https://tella.herokuapp.com/",
    description: "A privacy-based messaging app built with a MySQL database using Model View Controller (MVC) paradigm to focus on connecting with those you love.",
    tags: [
      "Express-handlebars",
      "Model-View-Controller paradigm",
      "MySQL2",
      "Sequelize",
      "Full-stack",
    ],
    date: "20 Feb, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/160572835-eec17800-f5bd-4da4-8790-6f1717f2348a.png",
  },

  {
    id: 12,
    image: "https://user-images.githubusercontent.com/71200950/153731140-8f9f2f21-e13b-490c-98d8-cc3351267340.png",
    title: "Tech Blog",
    github: "https://github.com/tringuyen1086/tech-blog-main.git",
    demo: "https://tech-blog-main.herokuapp.com/",
    description: "The MVC Tech Blog is built with a MySQL database and CMS-style using Model View Controller (MVC) paradigm.",
    tags: [
      "Express-handlebars",
      "Model-View-Controller paradigm",
      "MySQL2",
      "Sequelize",
    ],
    date: "13 Feb, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/153731140-8f9f2f21-e13b-490c-98d8-cc3351267340.png",
  },
*/
  {
    id: 11,
    image: "https://user-images.githubusercontent.com/77648727/115477536-fa67cc80-a1f8-11eb-97e0-f35a8c902d4f.gif",
    title: "E-commerce Back End",
    github: "https://github.com/tringuyen1086/e-commerce-backend-pro.git",
    demo: "https://github.com/tringuyen1086/e-commerce-backend-pro.git",
    description: "A command line application to manage a company's employee database, using Node.js, Inquirer, Console.table and MySQL2.",
    tags: ["Express.js", "MySQL", "Sequelize"],
    date: "6 Feb, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/77648727/115477536-fa67cc80-a1f8-11eb-97e0-f35a8c902d4f.gif",
  },

  {
  id: 10,
  image: "https://user-images.githubusercontent.com/71200950/153754388-735d37b0-6846-4170-a9b7-5ad257e15c8b.png",
  title: "Employee Tracker",
  github: "https://github.com/tringuyen1086/employee-tracker-database.git",
  demo: "https://github.com/tringuyen1086/employee-tracker-database.git",
  description: "A command line application to manage a company's employee database, using Node.js, Inquirer, Console.table and MySQL2.",
  tags: ["Node.js", "Inquirer", "MySQL2", "Console.table"],
  date: "30 Jan, 2022",
  imgSrc:
    "https://user-images.githubusercontent.com/71200950/153754388-735d37b0-6846-4170-a9b7-5ad257e15c8b.png",
  },
/*
  // Remove cause of inactive Heroku from the group
  {
    id: 9,
    image: "https://user-images.githubusercontent.com/71200950/160828996-a50b0340-1ffb-46ac-b253-156880302a6a.png", 
    title: "Note Taker",
    github: "https://note-taker-pro-2022.herokuapp.com/",
    demo: "https://note-taker-pro-2022.herokuapp.com/",
    description: "This is a simple application for writing and saving note. It uses an Express.js backend. This application will save and retrieve note data from a JSON file.",
    tags: ["HTML", "CSS", "Express.js", "JSON", "UUID"],
    date: "23 Jan, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/160828996-a50b0340-1ffb-46ac-b253-156880302a6a.png", 
  },
*/
  {
    id: 8,
    image: "https://user-images.githubusercontent.com/71200950/160828228-c0fdf572-0ad2-429d-8eaa-38053530a307.png",
    title: "Team Profile Generator",
    github: "https://github.com/tringuyen1086/team-profile-generator-pro.git",
    demo: "https://github.com/tringuyen1086/team-profile-generator-pro.git",
    description: "A command line application to dynamically create a webpage of key employee info (names, roles, contact info).",
    tags: ["HTML", "CSS", "JavaScript", "Node.js", "Jest", "Inquirer"],
    date: "16 Jan, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/160828228-c0fdf572-0ad2-429d-8eaa-38053530a307.png",
  },

  {
    id: 7,
    image: "https://user-images.githubusercontent.com/71200950/160827637-92cf5056-c638-4c6c-a73c-b0e731c3508e.png",
    title: "Pro README Generator",
    github:"https://github.com/tringuyen1086/readme-generator-ultimate.git",
    demo: "https://github.com/tringuyen1086/readme-generator-ultimate.git",
    description: "A node.js program is used to create README.md by asking the user a series of inquirer-driven questions. The README.md is generated in the 'written' folder.",
    tags: ["HTML", "Markdown", "Node.js", "Inquirer"],
    date: "9 Jan, 2022",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/160827637-92cf5056-c638-4c6c-a73c-b0e731c3508e.png",
  },

  {
    id: 6,
    image: "https://user-images.githubusercontent.com/71200950/183293376-5b7647ea-8934-46ff-95ec-ff742ce84ba6.png",
    title: " Interactive Front-End Project - Meme Stocker",
    github: "https://github.com/tringuyen1086/meme-stocker.git",
    demo: "https://tringuyen1086.github.io/meme-stocker/",
    description: "___MemeStocker___ is a Meme Stock tracker that pulls the ___Top 10___ tickers from the Reddit subreddit - Wallstreetbets. The goal of the application is to equip retail investors interested in the meme stock trend with the top trending tickers.",
    tags: [
      "HTML",
      "Tailwind CSS",
      "JQuery",
      "JavaScript",
      "Wallstreetbets API",
      "Financial Modeling Prep",
    ],
    date: "19 Dec, 2021",
    imgSrc:
    "https://user-images.githubusercontent.com/71200950/183293376-5b7647ea-8934-46ff-95ec-ff742ce84ba6.png"
  },

  {
    id: 5,
    image: "https://user-images.githubusercontent.com/71200950/160575131-1a49f378-8084-44f7-afe6-ccb4f62376b1.png",
    title: "Weather Dashboard",
    github: "https://github.com/tringuyen1086/work-scheduler-ultimate.git",
    demo: "https://tringuyen1086.github.io/weather-dashboard-ultimate/",
    description: "A web-based weather dashboard shows the current weather of selected city with the upcoming 5-day forecast.",
    tags: [
      "HTML",
      "CSS",
      "JavaScript",
      "Open Weather API",
      "Bootstrap",
      "Moment.js",
    ],
    date: "12 Dec, 2021",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/160575131-1a49f378-8084-44f7-afe6-ccb4f62376b1.png",
  },

  {
    id: 4,
    image: "https://user-images.githubusercontent.com/71200950/160574773-d89d1a1d-0d20-4bf9-a90d-21424cf65e37.png",
    title: "Work Day Scheduler",
    github: "https://github.com/tringuyen1086/work-scheduler-ultimate.git",
    demo: "https://tringuyen1086.github.io/work-scheduler-ultimate/",
    description: "A work-scheduler application to set time-block for improving employee's productivity.",
    tags: ["HTML", "CSS", "JavaScript", "jQuery", "Moment.js", "API"],
    date: "5 Dec, 2021",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/160574773-d89d1a1d-0d20-4bf9-a90d-21424cf65e37.png",
  },

  {
    id: 3,
    image: "https://user-images.githubusercontent.com/71200950/160574210-5c932d83-cee2-4a00-9e87-0917c4ad2a85.png",
    title: "Coding Quiz Challenge",
    github: "https://tringuyen1086.github.io/code-quiz-basis/",
    description: "This is a Code Quiz web page that is primarily built using JavaScript.",
    demo: "https://tringuyen1086.github.io/code-quiz-basis/",
    tags: ["JavaScript", "Web APIs"],
    date: "28 Nov, 2021",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/160574210-5c932d83-cee2-4a00-9e87-0917c4ad2a85.png",
  },

  {
    id: 2,
    image: "https://user-images.githubusercontent.com/71200950/160573468-1c23a078-35f8-4041-a1aa-1c7b3d6d6cab.png",
    title: "Password Generator",
    github: "https://github.com/tringuyen1086/password-generator-ultimate.git",
    demo: "https://tringuyen1086.github.io/password-generator-ultimate/", 
    description: "This is a password generator that generates random passwords based on user input. It is a work in progress that will be updated over time.",
    tags: ["HTML", "CSS", "JavaScript"],
    date: "21 Nov, 2021",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/160573468-1c23a078-35f8-4041-a1aa-1c7b3d6d6cab.png",
  },

  {
    id: 1,
    image: "https://user-images.githubusercontent.com/71200950/160569086-2613e589-2a5c-4450-abbd-43174dab8320.png",
    title: "Professional Portfolio",
    github: "https://github.com/tringuyen1086/professional-portfolio-tri-nguyen.git",
    demo: "https://tringuyen1086.github.io/professional-portfolio-tri-nguyen",
    description: "This portfolio is a work in progress that shows activities, homework, and projects for UC Berkeley Coding Boot Camp. I will add more links, styles, and codes to improve my portfolio over time.",
    tags: ["HTML", "CSS"],
    date: "14 Nov, 2021",
    imgSrc:
      "https://user-images.githubusercontent.com/71200950/160569086-2613e589-2a5c-4450-abbd-43174dab8320.png",
  },

];

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo, description}) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className='portfolio__item-image'>
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <h5>{description}</h5>
                <div className='portfolio__item-cta'>
                  <a href={github} className='btn' target='_blank'>Github</a>
                  <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio





 {/*
 const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
              <article className="portfolio__item">
                <div className="portfolio_item-image">
                  <img src="https://user-images.githubusercontent.com/71200950/183293376-5b7647ea-8934-46ff-95ec-ff742ce84ba6.png" alt="Meme Stocker"/>
                  <h3>Meme Stocker</h3>
                  <div className="portfolio__item-cta">
                    <a href="https://github.com/tringuyen1086/meme-stocker.git" className="btn" target="_blank">GitHub</a>
                    <a href="https://tringuyen1086.github.io/meme-stocker/" className="btn btn-primary" target="_blank">Live Demo</a>
                  </div>
                </div>
              </article>

              <article className="portfolio__item">
                <div className="portfolio_item-image">
                  <img src="https://user-images.githubusercontent.com/71200950/160572835-eec17800-f5bd-4da4-8790-6f1717f2348a.png" alt="Messaging App" alt="" />
                  <h3>Messaging App</h3>
                  <div className="portfolio__item-cta">
                    <a href="https://github.com/scottrohrig/messaging-app.git" className="btn" target="_blank">GitHub</a>
                    <a href="https://tella.herokuapp.com/" className="btn btn-primary" target="_blank">Live Demo</a>
                  </div>
                </div>
              </article>

              <article className="portfolio__item">
                <div className="portfolio_item-image">
                  <img src="https://user-images.githubusercontent.com/71200950/167048110-9e85c0f8-bf31-43d8-85c0-90e2ee6b218d.png" alt="Maintainable App" alt="" />
                  <h3>Maintainable App</h3>
                  <div className="portfolio__item-cta">
                    <a href="https://github.com/horsfalm/maintainable.git" className="btn" target="_blank">GitHub</a>
                    <a href="https://maintainable.herokuapp.com/" className="btn btn-primary" target="_blank">Live Demo</a>
                  </div>
                </div>
              </article>

              <article className="portfolio__item">
                <div className="portfolio_item-image">
                  <img src="https://user-images.githubusercontent.com/71200950/160569086-2613e589-2a5c-4450-abbd-43174dab8320.png" alt="portfolio1" alt="" />
                  <h3>This is a portfolio item title</h3>
                  <div className="portfolio__item-cta">
                    <a href="https://gtihub.com" className="btn" target="_blank">GitHub</a>
                    <a href="https://tringuyen1086.github.io/professional-portfolio-tri-nguyen" className="btn btn-primary" target="_blank">Live Demo</a>
                  </div>
                </div>
              </article>

              <article className="portfolio__item">
                <div className="portfolio_item-image">
                  <img src="https://user-images.githubusercontent.com/71200950/160569086-2613e589-2a5c-4450-abbd-43174dab8320.png" alt="portfolio1" alt="" />
                  <h3>This is a portfolio item title</h3>
                  <div className="portfolio__item-cta">
                    <a href="https://gtihub.com" className="btn" target="_blank">GitHub</a>
                    <a href="https://tringuyen1086.github.io/professional-portfolio-tri-nguyen" className="btn btn-primary" target="_blank">Live Demo</a>
                  </div>
                </div>
              </article>

              <article className="portfolio__item">
                <div className="portfolio_item-image">
                  <img src="https://user-images.githubusercontent.com/71200950/160569086-2613e589-2a5c-4450-abbd-43174dab8320.png" alt="portfolio1" alt="" />
                  <h3>This is a portfolio item title</h3>
                  <div className="portfolio__item-cta">
                    <a href="https://gtihub.com" className="btn" target="_blank">GitHub</a>
                    <a href="https://tringuyen1086.github.io/professional-portfolio-tri-nguyen" className="btn btn-primary" target="_blank">Live Demo</a>
                  </div>
                </div>
              </article> 



      </div>
    </section>
  )
}



export default Portfolio

*/}