import React from 'react'
import './about.css'
import ME from '../../assets/me-about.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>2+ Years</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>Be my clients</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>20+ Completed</small>
            </article>
          </div>
          
          <p>
          Passionate web and software developer. I love to create applications that are simple yet beautiful to make people's lives easier. I am always looking for optimized solutions to enhance user experience.

          <br /> <br />
          Being a passionate problem solver, I decided to switch my career from Finance to the Tech world. Not only am I motivated by the challenges of new projects, but also I love spending my time on resolving problems on computers. Eventually, I am making my dream come true by pursuing my career path in web and software development.

          <br /> <br />
          Graduated the full stack web developer boot camp at UC Berkeley Extension and the Java Accelerator boot camp at the Southern Methodist University (Cognizant), I am proactively looking for opening positions where I can find opportunities to expand my knowledge and work on challenging projects. At the moment, I am taking college courses to get my BS/MS in computer science / software engineer.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About