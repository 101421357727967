import React from 'react'
import './header.css'
import { useEffect, useRef } from 'react';
import { init } from 'ityped'
import CTA from './CTA'
import ME from '../../assets/me.png'
import HeaderSocials from './HeaderSocials';


const Header = () => {
  const textRef = useRef();

  useEffect (()=>{
    init(textRef.current, {
     showCursor: true,
     backDelay: 1800,
     backSpeed: 80,
     strings: ["Passionate Learner", "Problem Solver", "Critical Thinker"],   
    });

  }, []);

  return (
    <header className="header">
      <div className="container header__container">
        <h3>Welcome to My Website</h3>
        <h1>Tri Nguyen</h1>
        <h3 className="text-light">
          Full Stack Developer <br>
          </br> <span class="text-ref" ref={textRef}></span>
        </h3>

        <CTA />

        <HeaderSocials />

        <div className="me">
          <img src={ME} alt="me" />
        </div>

        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>

    </header>    
 
    )
}

export default Header